import React, { useState } from "react";
import "./App.css";
import { ReactComponent as Goat } from "./images/goatparty1.svg";

function App() {
  const randomColor = require("randomcolor");
  const [goatColor, setGoatColor] = useState(randomColor);

  function handleColorChange() {
    setGoatColor(randomColor);
  }

  return (
    <div className="App">
      <button className="goat-party" onClick={handleColorChange}>
        Goat party
      </button>
      <Goat title="goat" className="rotate" stroke={goatColor} />
    </div>
  );
}

export default App;
